'use client'
import React, { ReactNode } from 'react';
import { ThemeProvider } from 'next-themes';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { THEME } from '@/theme/default';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

type IThemeProviderProps = {
  children: ReactNode;
}

export default function ThemeCustomProvider({ children }: IThemeProviderProps) {
  return (
    <ThemeProvider attribute="class" defaultTheme="light">
      <MuiThemeProvider theme={THEME}>
        <ScopedCssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeProvider>
  );
}