'use client';

import InfoIcon from '@mui/icons-material/Info';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Button from '@mui/material/Button';
import dynamic from 'next/dynamic';
import React from 'react';

const CookieConsent = dynamic(() => import('react-cookie-consent'), { ssr: false });

export default function CustomCookieConsent() {
  return (
    <CookieConsent
      location="bottom"
      cookieName="__cogni2-cookie-permission"
      style={{ background: "#2B373B" }}
      enableDeclineButton
      buttonStyle={{ background:"transparent", boxShadow: "none", border: "none", fontSize: "11px", margin: "0 10px" }}
      declineButtonStyle={{ background:"transparent", boxShadow: "none", border: "none", fontSize: "11px", margin: 0 }}
      buttonText={
        <Button
          variant="text"
          startIcon={<ThumbUpIcon />}
          className="min-w-auto normal-case font-normal text-white"
        >
          Aceitar
        </Button>
      }
      declineButtonText={
        <Button
          variant="text"
          startIcon={<ThumbDownIcon />}
          className="min-w-auto normal-case font-normal text-white opacity-50"
        >
          Recusar
        </Button>
      }
      expires={150}
    >
      <small className="font-light">
        <InfoIcon /> Este site usa <strong>cookies</strong> para melhorar sua experiência de navegação.
      </small>
    </CookieConsent>
  );
}