'use client'
import { createTheme } from '@mui/material/styles';

export const THEME = createTheme({
  typography: {
    "fontFamily": `"Poppins", sans-serif`
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input:focus, & input:active': {
            outline: 'none !important',
            border: 'none !important',
            boxShadow: 'none !important'
          },
          '& input:not(.MuiInputBase-inputSizeSmall)': {
            paddingTop: '1.1rem',
            paddingBottom: '1rem',
          },
          '& input, & input::placeholder': {
            background: 'transparent',
            fontSize: '12px'
          },
          '& label:not(.MuiInputLabel-shrink)': {
            fontSize: '13px'
          },
          '& label.MuiInputLabel-sizeSmall:not(.Mui-focused)': {
            fontSize: '11px'
          }
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          verticalAlign: 'text-top'
        },
      },
    }
  }
});