'use client'
import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import en from '@/locales/en.json';
import pt from '@/locales/pt-BR.json';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type ILocaleProviderProps = {
  children: ReactNode;
}

export default function LocaleProvider({ children }: ILocaleProviderProps) {
  const [locale, setLocale] = useState<string>();

  useLayoutEffect(() => {
    setLocale(navigator.language);
  }, []);

  const messages = locale?.includes('en') ? en : pt;

  return locale && (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocalizationProvider>
  );
}